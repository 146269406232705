<template>
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card">
                <h5>
                    <font-awesome-icon icon="project-diagram" /> Projeto
                    <span v-if="projeto"> - {{ projeto?.nome }}</span>
                </h5>
                <div class="p-d-flex">
                    <h5><font-awesome-icon icon="user-tie" /> Credor</h5>
                    <btn-refresh @click="atualizar()" v-if="acao === 'ATUALIZAR'"></btn-refresh>
                </div>
                <erros-box :erros="erros"></erros-box>
                <div class="p-formgrid p-grid p-fluid">
                    <div class="p-field p-col-12 p-lg-3 p-xl-2">
                        <label class="required">Tipo da Pessoa</label>
                        <Dropdown v-model="tipoPessoa" :options="tiposPessoas" optionLabel="descricao" optionValue="tipoPessoa" />
                    </div>
                    <div class="p-field p-col-12 p-lg-3 p-xl-2">
                        <label v-if="tipoPessoa == 'PF'">CPF</label>
                        <InputMask v-model="cpfCnpj" mask="999.999.999-99" :unmask="true" :disabled="desabilitaForm" v-if="tipoPessoa == 'PF'" />
                        <label v-if="tipoPessoa == 'PJ'">CNPJ</label>
                        <InputMask v-model="cpfCnpj" mask="99.999.999/9999-99" :unmask="true" :disabled="desabilitaForm" v-if="tipoPessoa == 'PJ'" />
                    </div>
                    <div class="p-field p-col-12 p-lg-6 p-xl-8">
                        <label class="required">Nome</label>
                        <InputText type="text" v-model="nome" :disabled="desabilitaForm" @input="v$.nome.$touch()" />
                        <small class="p-error" v-if="v$.nome.$error">O nome do projeto é obrigatório</small>
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-xl-2">
                        <label>Identidade</label>
                        <InputText type="text" v-model="identidade" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-xl-2">
                        <label>Orgão Emissor</label>
                        <InputText type="text" v-model="identidadeOrgaoEmissor" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-lg-1 p-xl-1">
                        <label>UF</label>
                        <Dropdown
                            v-model="identidadeUfEmissora"
                            :options="estados"
                            optionLabel="text"
                            optionValue="value"
                            :disabled="desabilitaForm"
                        />
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-xl-3" v-if="isRecuperacaoJudicial">
                        <label>Classe</label>
                        <InputText type="text" v-model="classe" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-xl-3" v-if="isCessaoCredito">
                        <label class="required">Situação Cadastral</label>
                        <Dropdown
                            v-model="situacaoCadastralId"
                            :options="situacoesCadastrais"
                            optionLabel="descricao"
                            optionValue="situacaoCadastralId"
                        />
                    </div>
                    <div class="p-field p-col-12 p-lg-2 p-xl-2">
                        <label class="required">Natureza</label>
                        <Dropdown v-model="naturezaId" :options="naturezas" optionLabel="descricao" optionValue="naturezaId" />
                    </div>
                    <div class="p-field p-col-12 p-lg-3 p-xl-2">
                        <label class="required">Origem</label>
                        <Dropdown v-model="origemId" :options="origens" optionLabel="descricao" optionValue="origemId" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isCessaoCredito">
                        <label>Valor Nominal</label>
                        <InputNumber v-model="valorNominal" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isCessaoCredito">
                        <label>Valor Corrigido</label>
                        <InputNumber v-model="valorCorrigido" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isCessaoCredito">
                        <label>Preço Target</label>
                        <InputNumber v-model="precoTarget" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isCessaoCredito">
                        <label>Preço Máximo</label>
                        <InputNumber v-model="precoMaximo" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isRecuperacaoJudicial">
                        <label>Valor QGC</label>
                        <InputNumber v-model="valorQGC" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3" v-if="isRecuperacaoJudicial">
                        <label>Valor do Plano</label>
                        <InputNumber v-model="valorPlano" mode="currency" currency="BRL" locale="pt-BR" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-lg-6">
                        <label>Histórico Jurídico</label>
                        <InputText type="text" v-model="historicoJuridico" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-lg-6">
                        <label>Contato</label>
                        <InputText type="text" v-model="contato" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3">
                        <label>Telefone 1</label>
                        <InputMask v-model="telefone1" mask="(99)99999999?9" :unmask="true" :disabled="desabilitaForm" />
                        <!-- <small class="p-error" v-if="v$.telefone1.$error">O telefone é obrigatório</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3">
                        <label>Telefone 2</label>
                        <InputMask v-model="telefone2" mask="(99)99999999?9" :unmask="true" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3">
                        <label>Email 1</label>
                        <InputText type="text" v-model="email1" :disabled="desabilitaForm" />
                        <!-- <small class="p-error" v-if="v$.email1.$error">O email é obrigatório</small> -->
                    </div>
                    <div class="p-field p-col-12 p-md-6 p-lg-3">
                        <label>Email 2</label>
                        <InputText type="text" v-model="email2" :disabled="desabilitaForm" />
                    </div>
                    <div class="p-field p-col-12">
                        <endereco-form v-model="endereco" :enderecoAtual="enderecoAtual" :desabilitaForm="desabilitaForm"></endereco-form>
                    </div>
                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-2">
                        <label class="required">Status Contato</label>
                        <Dropdown v-model="statusContatoId" :options="statusContatos" optionLabel="descricao" optionValue="statusContatoId" />
                    </div>
                    <div class="p-field p-col-12 p-md-2 p-lg-2" v-if="isCessaoCredito">
                        <label>Interesse</label>
                        <Dropdown v-model="statusInteresseId" :options="statusInteresses" optionLabel="descricao" optionValue="statusInteresseId" />
                    </div>

                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-2" v-if="isCessaoCredito">
                        <label class="required">Status Negociação</label>
                        <Dropdown
                            v-model="statusNegociacaoId"
                            :options="statusNegociacoes"
                            optionLabel="descricao"
                            optionValue="statusNegociacaoId"
                        />
                    </div>

                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-2">
                        <label class="required">Status Proposta</label>
                        <Dropdown v-model="statusPropostaId" :options="statusPropostas" optionLabel="descricao" optionValue="statusPropostaId" />
                    </div>

                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-2" v-if="isCessaoCredito">
                        <label class="required">Status Cessão</label>
                        <Dropdown v-model="statusCessaoId" :options="statusCessoes" optionLabel="descricao" optionValue="statusCessaoId" />
                    </div>

                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-2" v-if="isRecuperacaoJudicial">
                        <label class="required">Status Procuração</label>
                        <Dropdown
                            v-model="statusProcuracaoId"
                            :options="statusProcuracoes"
                            optionLabel="descricao"
                            optionValue="statusProcuracaoId"
                        />
                    </div>

                    <div class="p-field p-col-12 p-md-4 p-lg-3 p-xl-3">
                        <label>Responsável</label>
                        <InputText type="text" v-model="responsavel" :disabled="desabilitaForm" />
                    </div>

                    <div class="p-field p-col-12">
                        <label>Observações</label>
                        <Textarea v-model="observacoes" rows="8" :disabled="desabilitaForm" />
                    </div>
                </div>
                <slot name="botoes"></slot>
                <Button label="Cancelar" icon="pi pi-times-circle" class="p-button-secondary" @click="cancelar" v-if="!desabilitaForm" />
                <Button
                    label="Salvar"
                    icon="pi pi-check-circle"
                    class="p-ml-2 p-button-primary"
                    @click="confirmarSalvar()"
                    v-if="!desabilitaForm"
                    :disabled="v$.$invalid"
                />
            </div>
            <!-- {{ v$ }} -->
        </div>
    </div>
</template>

<script>
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import EnderecoForm from '../../shared/components/EnderecoFormComponent2';
import CredoresServices from './services';
import { estados2 } from '../../shared/components/estados';

export default {
    setup() {
        return { v$: useVuelidate() };
    },

    components: {
        EnderecoForm,
    },

    props: {
        projeto: {
            type: Object,
        },

        acao: {
            type: String,
        },

        credor: {
            type: Object,
        },

        erros: {
            type: Array,
        },

        cancelar: {
            type: Function,
        },

        isCessaoCredito: {
            type: Boolean,
            default: () => false,
        },

        isRecuperacaoJudicial: {
            type: Boolean,
            default: () => false,
        },
    },

    emits: ['salvar', 'atualizar'],

    data() {
        return {
            tipoPessoa: 'PF',
            cpfCnpj: null,
            identidade: null,
            identidadeOrgaoEmissor: null,
            identidadeUfEmissora: null,
            nome: null,
            situacaoCadastralId: null,
            naturezaId: null,
            historicoJuridico: null,
            valorNominal: 0,
            valorCorrigido: 0,
            precoTarget: 0,
            precoMaximo: 0,
            contato: null,
            telefone1: null,
            telefone2: null,
            email1: null,
            email2: null,
            endereco: null,
            enderecoAtual: null,
            statusContatoId: null,
            statusInteresseId: null,
            statusNegociacaoId: null,
            statusPropostaId: null,
            statusCessaoId: null,
            origemId: null,
            statusProcuracaoId: null,
            responsavel: null,
            observacoes: null,
            classe: null,
            valorQGC: 0,
            valorPlano: 0,
            tiposPessoas: [
                { tipoPessoa: 'PF', descricao: 'PESSOA FÍSICA' },
                { tipoPessoa: 'PJ', descricao: 'PESSOA JURÍDICA' },
            ],
            naturezas: [],
            situacoesCadastrais: [],
            statusCessoes: [],
            statusContatos: [],
            statusNegociacoes: [],
            statusPropostas: [],
            statusProcuracoes: [],
            statusInteresses: [],
            origens: [],
            estados: [...estados2],
        };
    },

    validations() {
        return {
            nome: { required },
            situacaoCadastralId: {
                situacaoCadastralOk: () => {
                    if (this.isCessaoCredito) {
                        return this.situacaoCadastralId !== null;
                    }
                    return true;
                },
            },
            naturezaId: { required },
            statusContatoId: { required },
            statusNegociacaoId: {
                negociacaoOk: () => {
                    if (this.isCessaoCredito) {
                        return this.statusNegociacaoId !== null;
                    }
                    return true;
                },
            },
            statusPropostaId: { required },
            statusCessaoId: {
                cessaoOk: () => {
                    if (this.isCessaoCredito) {
                        return this.statusCessaoId !== null;
                    }
                    return true;
                },
            },
            statusProcuracaoId: {
                procuracaoOk: () => {
                    if (this.isRecuperacaoJudicial) {
                        return this.statusProcuracaoId !== null;
                    }
                    return true;
                },
            },
            statusInteresseId: {
                interesseOk: () => {
                    if (this.isCessaoCredito) {
                        return this.statusInteresseId !== null;
                    }
                    return true;
                },
            },
            origemId: { required },
        };
    },

    methods: {
        obterOpcoes() {
            this.$store.dispatch('addRequest');
            CredoresServices.obterOpcoesFormulario(this.tipoProjeto).then((response) => {
                if (response && response.success) {
                    this.naturezas = response.data.naturezas;
                    this.situacoesCadastrais = response.data.situacoesCadastrais;
                    this.statusCessoes = response.data.statusCessoes;
                    this.statusContatos = response.data.statusContatos;
                    this.statusNegociacoes = response.data.statusNegociacoes;
                    this.statusPropostas = response.data.statusPropostas;
                    this.statusProcuracoes = response.data.statusProcuracoes;
                    this.origens = response.data.origens;
                    this.statusInteresses = response.data.statusInteresses;
                } else {
                    this.projetos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },

        preencher() {
            this.tipoPessoa = this.credor?.tipoPessoa;
            this.cpfCnpj = this.credor?.cpfCnpj;
            this.identidade = this.credor?.identidade;
            this.identidadeOrgaoEmissor = this.credor?.identidadeOrgaoEmissor;
            this.identidadeUfEmissora = this.credor?.identidadeUfEmissora;
            this.nome = this.credor?.nome;
            this.situacaoCadastralId = this.credor?.situacaoCadastralId;
            this.naturezaId = this.credor?.naturezaId;
            this.historicoJuridico = this.credor?.historicoJuridico;
            this.classe = this.credor?.classe;
            this.valorNominal = this.credor?.valorNominal;
            this.valorCorrigido = this.credor?.valorCorrigido;
            this.precoTarget = this.credor?.precoTarget;
            this.precoMaximo = this.credor?.precoMaximo;
            this.valorQGC = this.credor?.valorQGC;
            this.valorPlano = this.credor?.valorPlano;
            this.contato = this.credor?.contato;
            this.telefone1 = this.credor?.telefone1;
            this.telefone2 = this.credor?.telefone2;
            this.email1 = this.credor?.email1;
            this.email2 = this.credor?.email2;
            this.enderecoAtual = this.credor?.endereco;
            this.statusContatoId = this.credor?.statusContatoId;
            this.statusInteresseId = this.credor?.statusInteresseId;
            this.statusNegociacaoId = this.credor?.statusNegociacaoId;
            this.statusPropostaId = this.credor?.statusPropostaId;
            this.statusCessaoId = this.credor?.statusCessaoId;
            this.origemId = this.credor?.origemId;
            this.statusProcuracaoId = this.credor?.statusProcuracaoId;
            this.responsavel = this.credor?.responsavel;
            this.observacoes = this.credor?.observacoes;
            this.v$.$touch();
        },

        confirmarSalvar() {
            this.$confirm.require({
                message: `Tem certeza que deseja ${this.acao.toLowerCase()} o credor?`,
                header: 'Confirmação',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.salvar();
                },
            });
        },

        salvar() {
            console.log(this.temInteresse);
            let credorDto = {
                tipoPessoa: this.tipoPessoa,
                cpfCnpj: this.cpfCnpj,
                identidade: this.identidade,
                identidadeOrgaoEmissor: this.identidadeOrgaoEmissor,
                identidadeUfEmissora: this.identidadeUfEmissora,
                nome: this.nome,
                situacaoCadastralId: this.situacaoCadastralId,
                naturezaId: this.naturezaId,
                historicoJuridico: this.historicoJuridico,
                classe: this.classe,
                valorNominal: this.valorNominal,
                valorCorrigido: this.valorCorrigido,
                precoTarget: this.precoTarget,
                precoMaximo: this.precoMaximo,
                valorQGC: this.valorQGC,
                valorPlano: this.valorPlano,
                contato: this.contato,
                telefone1: this.telefone1,
                telefone2: this.telefone2,
                email1: this.email1,
                email2: this.email2,
                endereco: this.endereco,
                statusContatoId: this.statusContatoId,
                statusInteresseId: this.statusInteresseId,
                statusNegociacaoId: this.statusNegociacaoId,
                statusPropostaId: this.statusPropostaId,
                statusCessaoId: this.statusCessaoId,
                statusProcuracaoId: this.statusProcuracaoId,
                origemId: this.origemId,
                responsavel: this.responsavel,
                observacoes: this.observacoes,
            };
            this.$emit('salvar', credorDto);
        },

        atualizar() {
            this.$emit('atualizar');
        },
    },

    mounted() {
        this.obterOpcoes();
    },

    computed: {
        desabilitaForm() {
            return this.acao === 'DETALHAR';
        },

        tipoProjeto() {
            return this.isCessaoCredito ? 'cc' : 'rj';
        },
    },

    watch: {
        credor() {
            this.preencher();
        },
    },
};
</script>

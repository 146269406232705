<template>
    <div class="p-formgrid p-grid p-fluid">
        <div class="p-field p-col-12 p-md-3 p-lg-2">
            <label>CEP</label>
            <div class="p-inputgroup">
                <InputMask
                    v-model="cep"
                    mask="99999-999"
                    :unmask="true"
                    :disabled="desabilitaForm"
                    @keypress.enter="pesquisarCep()"
                />
                <Button
                    icon="pi pi-search"
                    @click="pesquisarCep()"
                    :disabled="processandoCep || desabilitaForm"
                    title="Clique para pesquisar o CEP"
                />
            </div>
        </div>
        <div class="p-field p-col-12 p-md-5">
            <label>Logradouro</label>
            <InputText type="text" v-model="logradouro" :disabled="desabilitaForm" />
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>Número</label>
            <InputText type="text" v-model="numero" :disabled="desabilitaForm" ref="numero" />
        </div>
        <div class="p-field p-col-12 p-md-2 p-lg-3">
            <label>Complemento</label>
            <InputText type="text" v-model="complemento" :disabled="desabilitaForm" />
        </div>
        <div class="p-field p-col-12 p-md-5">
            <label>Bairro</label>
            <InputText type="text" v-model="bairro" :disabled="desabilitaForm" />
        </div>
        <div class="p-field p-col-12 p-md-5">
            <label>Cidade</label>
            <InputText type="text" v-model="cidade" :disabled="desabilitaForm" />
        </div>
        <div class="p-field p-col-12 p-md-2">
            <label>UF</label>
            <Dropdown
                v-model="uf"
                :options="estados"
                optionLabel="text"
                optionValue="value"
                :disabled="desabilitaForm"
            />
        </div>
    </div>
</template>

<script>
// import useVuelidate from '@vuelidate/core';
import axios from 'axios';
import { estados2 } from './estados';
// import { required, minLength } from '@vuelidate/validators';

export default {
    // setup() {
    //     return { v$: useVuelidate() };
    // },

    props: {
        modelValue: {
            type: Object,
        },
        desabilitaForm: {
            type: Boolean,
            default: () => false,
        },
        mostrarMsgErro: {
            required: false,
            default: true,
        },
        enderecoAtual: {
            type: Object,
        },
    },

    emits: ['update:modelValue'],

    data() {
        return {
            processandoCep: false,
            logradouro: '',
            numero: '',
            complemento: '',
            bairro: '',
            cidade: '',
            uf: 'SP',
            cep: '',
            estados: estados2,
        };
    },

    // validations() {
    //     return {
    //         logradouro: { required },
    //         cep: { required, minLength: minLength(8) },
    //         numero: { required },
    //         bairro: { required },
    //         cidade: { required },
    //         uf: { required },
    //     };
    // },

    watch: {
        logradouro() {
            this.atualizarEndereco();
        },
        numero() {
            this.atualizarEndereco();
        },
        complemento() {
            this.atualizarEndereco();
        },
        bairro() {
            this.atualizarEndereco();
        },
        cidade() {
            this.atualizarEndereco();
        },
        uf() {
            this.atualizarEndereco();
        },
        cep() {
            this.atualizarEndereco();
        },
        enderecoAtual() {
            this.carregaEndereco();
        },
    },

    methods: {
        atualizarEndereco() {
            let endereco = {
                logradouro: this.logradouro,
                numero: this.numero,
                complemento: this.complemento,
                bairro: this.bairro,
                cidade: this.cidade,
                uf: this.uf,
                cep: this.cep,
                valido: true,
            };
            this.$emit('update:modelValue', endereco);
        },

        pesquisarCep() {
            if (this.cep.length === 8) {
                this.processandoCep = true;
                let promisse = axios.get(`https://viacep.com.br/ws/${this.cep}/json/`);
                promisse
                    .then(response => {
                        if (response.data.erro) {
                            console.log('CEP não localizado');
                            this.processandoCep = false;
                        } else {
                            this.logradouro = response.data.logradouro.toUpperCase();
                            this.bairro = response.data.bairro.toUpperCase();
                            this.cidade = response.data.localidade.toUpperCase();
                            this.uf = response.data.uf;
                            this.processandoCep = false;
                            // this.$refs.numero.focus();
                        }
                    })
                    .catch(response => {
                        console.log(response);
                        this.processandoCep = false;
                    });
            }
        },

        carregaEndereco() {
            if (this.enderecoAtual) {
                this.logradouro = this.enderecoAtual.logradouro;
                this.numero = this.enderecoAtual.numero;
                this.complemento = this.enderecoAtual.complemento;
                this.bairro = this.enderecoAtual.bairro;
                this.cidade = this.enderecoAtual.cidade;
                if (this.enderecoAtual.uf) {
                    this.uf = this.enderecoAtual.uf;
                } else {
                    this.uf = 'SP';
                }
                this.cep = this.enderecoAtual.cep;
            } else {
                this.logradouro = '';
                this.numero = '';
                this.complemento = '';
                this.bairro = '';
                this.cidade = '';
                this.cep = '';
                this.uf = 'SP';
            }
        },
    },
};
</script>
